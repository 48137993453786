import { Component } from 'react';

import Style from './FJEntrance.module.less';
import FJLocalStore from '../../../src/store/FJLocalStore';
import FJUtil from '../../../src/util/FJUtil';
import FJSvg from '../../FJSvg/FJSvg';
import arrowSvg from '../../../src/assets/icon/arrow.svg';
import FJButtonBrick from '../../ButtonBrick/FJButtonBrick';

export default class FJEntrance extends Component {
  constructor(props) {
    super(props);
  }

  jumpToPage = () => {
    const { url = FJUtil.getCurrentUrl() + '/editor/app?ratio=landscape', ealog = null } = this.props;
    if (ealog) {
      FJUtil.ealog(ealog.eventType, ealog.eventData);
    }
    window.location.href = url;
  };

  render() {
    const {
      title = FJLocalStore._('entrance-home'),
      buttonText = FJLocalStore._('StartNow'),
      url = FJUtil.getCurrentUrl() + '/editor/app?ratio=landscape',
      img = 'https://resource.flexclip.com/pages/home/home-footer2.webp?v=202447',
      bgImg = 'https://resource.flexclip.com/pages/home/bg-home-footer.webp',
    } = this.props;

    return (
      <div className={Style.entrance}>
        {bgImg && (
          <img
            src={bgImg}
            className={Style.entranceBg}
            width={1236}
            height={551}
            alt='jump to flexclip propaganda picture'
            loading='lazy'
          />
        )}
        <h2 className={Style.title}>{title}</h2>
        <FJButtonBrick URL={url} onClick={this.jumpToPage} className={Style.scratchBtn} type='other'>
          {buttonText}
          <FJSvg src={arrowSvg} />
        </FJButtonBrick>

        <div className={Style.entranceImgBox}>
          <img
            src={img}
            className={Style.entranceImg}
            width={1236}
            height={551}
            alt='jump to flexclip propaganda picture'
            loading='lazy'
          />
        </div>
      </div>
    );
  }
}
